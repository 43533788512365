var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor"},[_c('editor-menu-bubble',{staticClass:"menububble",attrs:{"editor":_vm.editor},on:{"hide":_vm.hideLinkMenu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var getMarkAttrs = ref.getMarkAttrs;
var menu = ref.menu;
return [_c('div',{staticClass:"menububble",class:{ 'is-active': menu.isActive },style:(("left: " + (menu.left) + "px; top: " + (menu.top + 5) + "px;"))},[(_vm.linkMenuIsActive)?_c('form',{staticClass:"menububble__form",on:{"submit":function($event){$event.preventDefault();return _vm.setLinkUrl(commands.link, _vm.linkUrl)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.linkUrl),expression:"linkUrl"}],ref:"linkInput",staticClass:"menububble__input",attrs:{"type":"text","placeholder":"https://"},domProps:{"value":(_vm.linkUrl)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.hideLinkMenu.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.linkUrl=$event.target.value}}}),_c('button',{staticClass:"menububble__button",attrs:{"type":"button"},on:{"click":function($event){return _vm.setLinkUrl(commands.link, null)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]):[_c('button',{staticClass:"menububble__button",attrs:{"type":"button"},on:{"click":function($event){_vm.showLinkMenu(getMarkAttrs('link'))}}},[_c('span',[_vm._v(_vm._s(isActive.link() ? "Update Link" : "Add Link"))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-link")])],1)]],2)]}}])}),_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menubar"},[_c('v-btn',{staticClass:"menubar__button",attrs:{"text":"","small":"","color":"grey darken-3"},on:{"click":commands.undo}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-undo")])],1),_c('v-btn',{staticClass:"menubar__button",attrs:{"text":"","small":"","color":"grey darken-3"},on:{"click":commands.redo}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-redo")])],1),_c('v-btn',{staticClass:"menubar__button",class:{ 'is-active': isActive.bold() },attrs:{"small":"","text":"","color":"grey darken-3"},on:{"click":commands.bold}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-bold")])],1),_c('v-btn',{staticClass:"menubar__button",class:{ 'is-active': isActive.italic() },attrs:{"text":"","small":"","color":"grey darken-3"},on:{"click":commands.italic}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-italic")])],1),_c('v-btn',{staticClass:"menubar__button",class:{ 'is-active': isActive.strike() },attrs:{"text":"","small":"","color":"grey darken-3"},on:{"click":commands.strike}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-strikethrough")])],1),_c('v-btn',{staticClass:"menubar__button",class:{ 'is-active': isActive.underline() },attrs:{"text":"","small":"","color":"grey darken-3"},on:{"click":commands.underline}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-format-underline")])],1)],1)]}}])}),_c('editor-content',{staticClass:"editor__content",attrs:{"editor":_vm.editor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }