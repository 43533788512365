<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <RoleSelect
        width="300px"
        :title="null"
        placeholder="Select a Gallery"
        endpoint="api/gallery"
        type="elibrary"
        :selected="{ id: selectedApp.id, name: selectedApp.name }"
        :onSelected="
          val => {
            selectedApp = val;
            selectedApp.id = val ? val.id : null;
            form.gallery_id = val.id;
            search = '';
            data = [];
            page = 1;
            loading = true;
            getData(val.id);
          }
        "
      />

      <div v-if="selectedApp.id" class="d-flex align-items-center">
        <div class="per-page-wrapper mr-4">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div v-if="selectedApp.id" class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.no`]="{ item }">
          {{ data.indexOf(item) + 1 }}
        </template>

        <template v-slot:[`item.description`]="{ item }">
          {{ strippedContent(item.description) }}
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDate(item.created_at) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <!-- <div v-ripple class="c-datatable-edit" @click="selectedData = item; form = { ...form, ...item }; openDialog()">
              <img :src="`${baseAppPath}/media/edit-icon.svg`">
            </div> -->
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
            <div v-ripple class="c-datatable-cancel" @click="openFile(item)">
              <img :src="`${baseAppPath}/media/download.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="dialog" width="400px" persistent>
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{ selectedData.name ? "Edit File" : "Tambah File" }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>

                <b-form-group>
                  <RoleSelect
                    v-if="dialog"
                    :title="'Gallery'"
                    placeholder="Select a Gallery"
                    endpoint="api/gallery"
                    type="elibrary"
                    :selected="selectedApp"
                    :onSelected="val => (form.gallery_id = val.id)"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Gallery is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Jenis File</div>
                  <b-dropdown
                    size="sm"
                    variant="link"
                    menu-class="px-0 text-primary"
                    toggle-class="py-0 px-0 w-100"
                    left
                    no-flip
                  >
                    <template v-slot:button-content>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        disabled
                        v-model="$v.form.type.$model"
                        :state="validateState('type')"
                        aria-describedby="input-4-live-feedback"
                      ></b-form-input>
                    </template>

                    <b-dropdown-item @click="form.type = 'Document'"
                      >Document</b-dropdown-item
                    >
                    <b-dropdown-item @click="form.type = 'Audio'"
                      >Audio</b-dropdown-item
                    >
                    <b-dropdown-item @click="form.type = 'Image'"
                      >Image</b-dropdown-item
                    >
                    <b-dropdown-item @click="form.type = 'Map'"
                      >Map</b-dropdown-item
                    >
                    <b-dropdown-item @click="form.type = 'Video'"
                      >Video</b-dropdown-item
                    >
                  </b-dropdown>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Kategori</div>
                  <b-dropdown
                    size="sm"
                    variant="link"
                    menu-class="px-0 text-primary"
                    toggle-class="py-0 px-0 w-100"
                    left
                    no-flip
                  >
                    <template v-slot:button-content>
                      <b-form-input
                        class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                        disabled
                        v-model="category[form.category]"
                        :state="validateState('category')"
                        aria-describedby="input-4-live-feedback"
                      ></b-form-input>
                    </template>

                    <b-dropdown-item @click="form.category = 0"
                      >Other</b-dropdown-item
                    >
                    <b-dropdown-item @click="form.category = 1"
                      >Publication</b-dropdown-item
                    >
                    <b-dropdown-item @click="form.category = 2"
                      >Raster</b-dropdown-item
                    >
                    <b-dropdown-item @click="form.category = 3"
                      >Vector</b-dropdown-item
                    >
                    <b-dropdown-item @click="form.category = 4"
                      >Module</b-dropdown-item
                    >
                  </b-dropdown>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Nama File</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Name is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Deskripsi</div>
                  <Editor v-model="form.description"></Editor>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">File</div>

                  <b-form-file
                    class="c-file-input form-control form-control-solid h-auto pl-3 pr-7 pb-0 mb-0"
                    v-model="form.file"
                    :state="validateState('file')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-file>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    File is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">License</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.license.$model"
                    :state="validateState('license')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    License is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import Moment from "moment";

import ApiService from "@/core/services/api.service";
import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";
import Editor from "@/view/components/Editor/Editor.vue";

export default {
  mixins: [validationMixin],
  props: [
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened"
  ],
  components: {
    RoleSelect,
    Editor
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "No", value: "no", width: "70px" },
        { text: "Nama File", value: "filename" },
        { text: "Jenis File", value: "attributes_type", width: "100px" },
        { text: "Deskripsi", value: "description", width: "100px" },
        { text: "Created At", value: "created_at", width: "150px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      selectedData: {},
      selectedApp: {
        id: null,
        name: null
      },

      category: ["Other", "Publication", "Raster", "Vector", "Module"],

      form: {
        gallery_id: null,
        name: null,
        type: null,
        category: null,
        description: null,
        file: null,
        license: null
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      errors: []
    };
  },
  validations: {
    form: {
      gallery_id: {
        required
      },
      file: {
        required
      },
      name: {
        required,
        minLength: minLength(1)
      },
      type: {
        required,
        minLength: minLength(1)
      },
      category: {
        required,
        minLength: minLength(1)
      },
      description: {
        required,
        minLength: minLength(1)
      },
      license: {
        required,
        minLength: minLength(1)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getData(gallery_id = this?.selectedApp?.id) {
      ApiService.get(`api/gallery/${gallery_id}`, "elibrary")
        .then(({ data }) => {
          if (data?.documents) {
            this.data = data.documents;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var formData = new FormData();
      formData.append("gallery_id", this.form.gallery_id);
      formData.append("name", this.form.name);
      formData.append("type", this.form.type);
      formData.append("category", this.form.category);
      formData.append("description", this.form.description);
      formData.append("license", this.form.license);
      formData.append("visibility", "1");

      if (this.form.file) {
        formData.append("file", this.form.file);
      }

      if (!this?.selectedData?.name) {
        // Create
        ApiService.post("api/document", formData, "elibrary")
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "File berhasil ditambahkan"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.put(`api/document/${this?.selectedData?.id}`, formData)
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data File berhasil diperbarui"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan menghapus File?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(`api/document/${id}`, "elibrary")
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        gallery_id: null,
        name: null,
        description: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    formatDate(date) {
      return Moment(date).format("DD-MM-YYYY HH:mm");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    },
    openFile(item) {
      window.open(
        `${process.env.VUE_APP_ELIBRARY_URL}/doc_repository/${item.gallery_id}/${item.filename}`,
        "_blank"
      );
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  }
};
</script>
