<template>
  <div>
    <div v-if="title" class="input-title">{{ title }}</div>
    <vue-select
      v-model="model"
      :disabled="disabled"
      :options="options"
      :label="label ? label : 'name'"
      :itemid="itemid ? itemid : 'id'"
      :placeholder="placeholder"
      class="c-select-input"
      :style="{
        'min-width': width ? width : '200px',
        'max-height': height ? height : 'inherit'
      }"
      @input="onChange()"
      @reset="reset()"
      @emptied="reset()"
    >
    </vue-select>
  </div>
</template>

<style lang="scss" scoped>
$vs-state-active-bg: #06b56f;
$vs-border-radius: 8px;
$vs-border-color: #d7d4d3;
$vs-component-line-height: 1.8;
$vs-dropdown-max-height: 200px;

@import "vue-select/src/scss/vue-select";
</style>

<style lang="scss">
.c-dialog {
  .c-select-input {
    input {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      background-color: inherit !important;
      border: inherit !important;
      border-radius: inherit !important;
    }
    ul {
      padding-left: 0px !important;
    }
    li {
      padding-left: 10px !important;
    }
  }
}

.v-dialog {
  background: #ffffff !important;
}
</style>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "RoleSelect",
  props: [
    "data",
    "endpoint",
    "type",
    "label",
    "itemid",
    "selected",
    "onSelected",
    "title",
    "placeholder",
    "disabled",
    "width",
    "height",
    "onReset"
  ],
  data() {
    return {
      model: null,
      options: []
    };
  },
  mounted() {
    if (this?.selected?.id) {
      this.model = this.selected;
    }

    if (this.data) {
      this.options = this.data;
    }

    this.getData();
  },
  methods: {
    getData() {
      if (this.endpoint) {
        ApiService.get(this.endpoint, this.type).then(({ data }) => {
          if (Array.isArray(data)) {
            this.options = data;
          } else {
            let _data = [];
            Object.keys(data).forEach(item => _data.push(data[item]));
            this.options = _data;
          }
        });
      }
    },
    onChange() {
      this.onSelected({
        id: null,
        ...this?.model
      });
    },
    reset() {
      try {
        this.onReset();
      } catch (error) {
        throw error;
      }
    }
  },
  computed: {
    // selected: {
    //   get: function(){
    //     return this.selected
    //   },
    //   // setter
    //   set: function(newValue){
    //     this.model = newValue
    //   }
    // }
  }
};
</script>
